class MatrizService {
  constructor() {}
  resources = () => ({
    listarCuentasMayores: {
      uri: `/v1/presupuesto/listar-cuenta-mayor`,
      method: ['get']
    },
    exportarCuentasMayores: {
      uri: `/v1/exportarcuentamayor`,
      method: ['get']
    },
    exportarModeloCuentasMayores: {
      uri: `/v1/exportarformatocuentamayor`,
      method: ['get']
    },
    importarCuentasMayores: {
      uri: `/v1/presupuesto/importar-cuenta-mayor`,
      method: ['post'],
      headers: { 'Content-Type': 'application/json' }
    },
    permisos: {
      uri: `/v1/usuario/permisos`,
      method: ['get']
    }
  });
}

export default MatrizService;
